import styled from 'styled-components';
import { palette } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.section`
  position: relative;
  display: flex;

  ${props => {
    if (props.basic) {
      return 'height: 100px;';
    } else {
      return props.containerHeight
        ? `
      height: ${props.containerHeight};
      `
        : `    
      height: 80vh;
      min-height: 700px;
      `;
    }
  }};

  box-sizing: border-box;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
  background-color: ${props => props.bgColor || '#ccc'};

  ${MEDIA.TABLET`
    ${props => {
      if (props.basic) {
        return 'height: 100px;';
      } else {
        return props.containerHeight
          ? `
        height: ${props.containerHeight};
        `
          : `    
        height: 100vh;
        min-height: 700px;
        `;
      }
    }};
  `};

  ${props => {
    return props.bgImage
      ? `
      background-image: url('${props.bgImage.childImageSharp.fluid.src}');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    `
      : '';
  }};

  .hero-content-container {
    display: block;
    max-width: ${props => props.containerMaxWidth || '540px'};

    ${MEDIA.PHONE`
      margin-top: 6.5rem;
    `};

    .cta-btn {
      display: none;
      margin: 2.5rem auto 0;
      text-align: center;

      ${MEDIA.PHONE`
        display: block;
      `};

      a {
        padding: 1rem 2.5rem;
        color: ${palette.white};
        background-color: ${palette.lightGreen};
      }
    }

    p {
      margin-top: 32px;
    }
  }

  .hero-down-arrow-container {
    position: absolute;
    text-align: center;
    bottom: 0;
    left: 0;
    width: 100%;
    -display: block;
    height: 65px;
  }

  h1 {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 72px;
    color: #fff;
    letter-spacing: -0.8px;
    text-align: center;
    line-height: 82px;

    ${MEDIA.PHONE`
      font-size: 46px;
      line-height: 52px;
    `};
  }

  h3 {
    font-family: Lora;
    font-size: 18px;
    letter-spacing: 4px;
    text-align: center;
    line-height: 82px;

    a {
      color: ${palette.white};

      &:hover {
        color: #efefef;
        border-color: #efefef !important;
      }

      &.active {
        border-bottom: solid 3px white;
      }
    }

    ${MEDIA.TABLET`
      display: none;
    `};
  }

  p {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 18px;
    color: ${palette.white};
    letter-spacing: 0.35px;
    text-align: center;
    line-height: 30px;
  }

  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
`;

export const BasicHero = styled.div`
  margin: 6rem 0;

  h1 {
    font-family: Lora;
    font-weight: 700;
    font-size: 6rem;
    letter-spacing: -0.8px;
    text-align: left;
    line-height: 112px;

    ${MEDIA.PHONE`
      font-size: 46px;
      line-height: 52px;
      ${'' /* text-align: center; */}
    `};

    span {
      border-bottom: 1px solid ${palette.lightPurple};
    }
  }

  p {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.35px;
    line-height: 30px;

    ${MEDIA.PHONE`
      font-size: 15px;
      margin-top: 0.5rem;
      ${'' /* text-align: center; */}
    `};
  }
`;
