import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { Container, BasicHero } from './hero.css';
import Title from 'components/title';
import Box from 'components/box';
import ShuffleText from 'components/shuffleText';

const Hero = ({
  title,
  tagline,
  eyebrow = {},
  bgColor = '#3E3E49',
  bgImage,
  containerHeight = '',
  containerMaxWidth,
  shuffleText = false,
  basic = false,
  cta = {},
}) => (
  <>
    <Container
      className="hero-section"
      bgImage={bgImage}
      bgColor={bgColor}
      containerHeight={containerHeight}
      containerMaxWidth={containerMaxWidth}
      basic={basic}
    >
      {!basic && (
        <>
          <div className="hero-content-container">
            {eyebrow.url && (
              <h3>
                <Link
                  to={eyebrow.url}
                  activeClassName="active"
                  partiallyActive={true}
                >
                  {eyebrow.text}
                </Link>
              </h3>
            )}

            {shuffleText ? (
              <h1>
                <ShuffleText>{title}</ShuffleText>
              </h1>
            ) : (
              <Title as="h1">{title}</Title>
            )}

            {tagline && <Title as="p">{tagline}</Title>}

            {cta.url && (
              <div className="cta-btn">
                <Link to={cta.url}>{cta.text}</Link>
              </div>
            )}
          </div>
        </>
      )}
    </Container>

    {basic && (
      <BasicHero>
        <Box>
          <Title as="h1">
            <span>{title}</span>
          </Title>
          {tagline ? <Title as="p">{tagline}</Title> : null}
        </Box>
      </BasicHero>
    )}
  </>
);

Hero.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  tagline: PropTypes.string,
  eyebrow: PropTypes.object,
  bgColor: PropTypes.string,
  bgImage: PropTypes.object,
  cta: PropTypes.object,
  containerMaxWidth: PropTypes.string,
  containerHeight: PropTypes.string,
  displayArrow: PropTypes.bool,
  shuffleText: PropTypes.bool,
  basic: PropTypes.bool,
};

export default Hero;
