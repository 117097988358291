import React from 'react';
import Layout from 'components/layout';
import Hero from 'components/sections/hero';

const NotFound = () => (
  <Layout>
    <Hero
      title="Whoops!"
      tagline="Looks like we can't find what you're looking for"
      bgColor="#5B5A72"
      displayArrow={false}
    />
  </Layout>
);

export default NotFound;
