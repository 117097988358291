import React, { Component } from 'react';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
import { Container } from './shuffleText.css';
import { shuffle } from 'helpers/utils';

const Item = posed.li();

export default class ShuffleText extends Component {
  state = {
    shuffledWords: Array.isArray(this.props.children)
      ? this.props.children[0].split(' ')
      : this.props.children.split(' '),
    isPredefinedList: Array.isArray(this.props.children) ? true : false,
  };

  componentDidMount() {
    setInterval(() => {
      if (this.state.isPredefinedList) {
        this.setState({
          shuffledWords: this.props.children[
            Math.floor(Math.random() * this.props.children.length)
          ].split(' '),
        });
      } else {
        this.setState({
          shuffledWords: shuffle(this.state.children.split(' ')),
        });
      }
    }, 12500);
  }

  render() {
    const { shuffledWords } = this.state;

    return (
      <Container>
        <PoseGroup>
          {shuffledWords.map(id => (
            <Item key={id}> {id} </Item>
          ))}
        </PoseGroup>
      </Container>
    );
  }
}

ShuffleText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  isPredefinedList: PropTypes.bool,
};
